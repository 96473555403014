import React, { useState } from "react"
// import { Link } from "gatsby"
import { connect, useSelector } from "react-redux"

import { FrequentQuestionsQuery } from "../../prismic/staticQueries"
import Layout from "../../components/layout"
import { BreadCrumbBanner, SubscriptionPanel } from "../../components/shared"

import { Accordion, Card, ListGroupItem } from "react-bootstrap"

const OurMission = () => {
  const language = useSelector(state => state.language)
  const [activeKey, setActiveKey] = useState(null)
  const { page_heading, questionsbox, banner_image } = FrequentQuestionsQuery(
    language
  )
  return (
    <>
      <Layout>
        <BreadCrumbBanner heading={page_heading} banner_image={banner_image} />

        <section className="sectionbg p-top-100 p-bottom-110 faqs">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="mb-5">
                  <div className="divider divider-simple text-center">
                    <h3>{page_heading[0].text}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-styles accordion--one">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="accordion accordion_one" id="accordion_one">
                    <div className="accordion-single">
                      <Accordion>
                        {questionsbox.map((item, ind) => (
                          <Card key={'faqs-'+ind}>
                            <Card.Header>
                              <Accordion.Toggle
                                as={ListGroupItem}
                                variant="link"
                                eventKey={ind + 1}
                                onClick={() => setActiveKey(ind)}
                                className={`accordion-question d-flex justify-content-between ${
                                  language === "ar-ae" ? "text-right" : ""
                                }`}
                              >
                                {item.question[0].text}
                                <i
                                  className={`fas arrow-icon fa-chevron-${
                                    activeKey === ind ? "up" : "down"
                                  }`}
                                ></i>
                              </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={ind + 1}>
                              <Card.Body>{item.answer[0].text}</Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        ))}
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <SubscriptionPanel />
      </Layout>
    </>
  )
}

const mapStateToProps = state => {
  return {
    language: state.language,
  }
}

export default connect(mapStateToProps)(OurMission)
